<script lang="ts" setup>
const { isAuthenticated } = storeToRefs(useUserStore());

watch(isAuthenticated, async () => {
  if (isAuthenticated.value)
    await navigateTo("/user");
}, { immediate: true });
</script>

<template>
  <UPage
    :ui="{
      center: {
        base: 'lg:col-span-5',
        full: '',
        narrow: '',
      },
      right: 'lg:col-span-5 hidden lg:block',
    }"
  >
    <div class="flex h-screen items-center justify-center">
      <div class="flex h-screen flex-col justify-between gap-8">
        <Logo class="mt-8 h-8" />
        <slot />
        <span class="mb-8 text-center text-sm text-gray-500 dark:text-gray-400">© Apolytus OÜ {{ new Date().getFullYear() }}</span>
      </div>
    </div>

    <template #right>
      <NuxtPicture
        src="/images/welcome.jpg"
        alt="A cute robot reading documents and answering questions on the phone"
        :img-attrs="{ class: 'h-full w-full object-cover rounded-l-[80px]' }"
      />
    </template>
  </UPage>
</template>
